export const environment = {
    production: true,
    env: 'prod',
    dsn: 'https://a1ddfe6d55184e5ead5fda147a4427f2@o1240364.ingest.sentry.io/4505226793844736',
    envVar: {
        backendUrl: 'https://stage.printportal.clearchannel-se.client.netconsult.cloud/backend/',
        socketUrl: 'wss://websocket.stage.printportal.clearchannel-se.client.netconsult.cloud:443',
    },
    features: {
        batRequestFlowEnabled: false, // This is a feature specific for the French market
        importAddressesEnabled: false,
        weekNumbersEnabled: true,
    }
};
